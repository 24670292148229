.filter-menu {
  @apply sm:flex sm:flex-col;

  .active {
    @apply text-black border-transparent;

    span {
      @apply border-black border-b border-solid;
    }
  }

  li {
    cursor: pointer;
    @apply sm:mb-[10px];

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
    @screen sm {
      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }

    span {
      @apply border-b border-solid border-transparent;
      transition: var(--default-transition);
      @apply font-serif font-medium;

      &:hover {
        @apply text-black border-black;
      }
    }
  }
}

//Portfolio-classic
.portfolio-classic {
  @apply p-0 rounded-[6px] overflow-hidden;
  box-shadow: 0 0 30px rgb(0, 0, 0, 0.08);
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-5px);

    .portfolio-image {
      img {
        transform: scale(1, 1);
        opacity: 0.15;
      }

      .portfolio-icon {
        opacity: 1;

        a,
        > div {
          opacity: 1;
          transition-delay: 0.1s;
          transform: translateY(0);

          &:hover {
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }

  .portfolio-image {
    @apply relative overflow-hidden;
    transition: var(--default-transition);
    background: var(--overlay-color, #000);

    img {
      @apply w-full;
      transition: var(--default-transition);
    }

    .portfolio-icon {
      @apply inline-block absolute align-middle top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center opacity-0;

      a,
      > div {
        @apply w-[40px] h-[40px] inline-block align-middle leading-[40px] text-center my-0 mx-[5px] relative z-[3] top-1/2 bg-[#fff] text-[#262b35] rounded-[50%] cursor-pointer;
        opacity: 0;
        transform: translateY(15px);
        transition: var(--default-transition);

        &:last-child {
          transition-delay: 0.2s;
        }

        i {
          @apply text-sm;
        }
      }
    }
  }

  .portfolio-caption {
    @apply py-[30px] bg-[#fff] text-center lg:py-[20px];

    a {
      span {
        @apply font-medium text-darkgray font-serif;
      }
    }
  }
}

// Portfolio-Boxed
.portfolio-box-boxed {
  @apply relative overflow-hidden;

  img {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  .portfolio-hover {
    @apply absolute left-0 right-0 text-center font-serif z-[999] my-0 mx-auto w-[90%] bottom-0 bg-[#fff] text-[#000] p-[15px] rounded-[3px];
  }

  &:hover {
    .portfolio-hover {
      @apply my-[15px] mx-auto;
      transform: translateY(0);
    }
  }
}

.portfolio-boxed {
  .portfolio-image {
    img {
      transition: var(--default-transition);
    }

    .porfolio-hover {
      @apply left-[20px] bottom-[20px] h-auto;
      opacity: 0;
      width: calc(100% - 40px);
      top: inherit;
      transform: translateY(100%);
      transition: var(--default-transition);

      i {
        transition: var(--default-transition);
      }

      &:hover {
        i {
          transform: translateX(5px);
        }
      }
    }
  }

  &:hover {
    .portfolio-image {
      img {
        transform: scale(1.1);
        transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }

    .porfolio-hover {
      opacity: 1;
      box-shadow: 0 0 15px rgb(0 0 0 / 20%);
      transform: translateY(0);
    }
  }
}

// portfolio-colorful
.portfolio-colorful {
  .portfolio-box {
    @apply relative;

    &:hover {
      .portfolio-hover {
        @apply opacity-100;

        i {
          @apply opacity-100 translate-x-0;
        }

        .portfolio-content {
          span,
          h6 {
            transform: translateY(0);
          }
        }
      }
    }

    img {
      @apply inline-block w-full h-auto;
    }

    .portfolio-hover {
      @apply absolute w-full h-full left-0 top-0 opacity-0;
      transition: var(--default-transition);
      background: var(--brand-color);

      i {
        @apply absolute opacity-0 top-12 right-16 text-[28px] text-white;
        transition: var(--default-transition);
        transform: translateX(15px);
      }

      .portfolio-content {
        @apply absolute overflow-hidden text-left bottom-12 left-16;
        transition: var(--default-transition);

        span {
          transform: translateY(-100px);
          transition: var(--default-transition);
        }

        h6 {
          @apply font-semibold text-white font-serif text-[1.8rem] leading-[2.5rem];
          transform: translateY(100px);
          transition: var(--default-transition);
          transition-duration: 0.5s;
        }
      }
    }
  }
}

// portfolio-bordered
.portfolio-bordered {
  @apply overflow-hidden;

  .portfolio-image {
    @apply relative;
    background: var(--overlay-color);

    img {
      transition: var(--default-transition);
    }

    .portfolio-border-hover {
      @apply flex justify-center items-center bg-white absolute w-full h-full left-0 top-0 opacity-0;
      transition: var(--default-transition);

      .scale {
        opacity: 0;
        transform: scale3d(1, 1, 1);
        transition: var(--default-transition);
      }
    }

    &:hover {
      img {
        opacity: 0.9;
        filter: blur(5px);
        transform: scale(1.1, 1.1);
      }

      .portfolio-border-hover {
        @apply left-[20px] top-[20px];
        opacity: 1;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        transform: scale3d(1, 1, 1);

        .scale {
          opacity: 1;
        }
      }
    }
  }
}

// Portfolio Overlay
.portfolio-overlay {
  .portfolio-image {
    background: var(--overlay-color, #000);

    img {
      transition: var(--default-transition);
    }
  }

  .portfolio-hover {
    @apply top-0 left-0 w-full h-full opacity-0;
    transition: var(--default-transition);

    i {
      @apply absolute top-2/4 left-2/4;
      transform: translate(-50%, calc(-50% - 15px));
      transition: var(--default-transition);
    }
  }

  &:hover {
    .portfolio-image {
      img {
        transform: scale(1.1);
        opacity: 0.15;
      }
    }

    .portfolio-hover {
      opacity: 1;

      i {
        transform: translate(-50%, -50%);
      }
    }
  }
}

// Portfolio Switchimg
.portfolio-switchimg {
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  .portfolio-image {
    @apply overflow-hidden relative rounded-[4px];
    transition: var(--default-transition);

    img {
      transition: var(--default-transition);
    }

    .portfolio-switch-image {
      @apply absolute top-0 left-0 w-full h-full;
      transition: var(--default-transition);
      opacity: 0;
      transform: scale3d(1.1, 1.1, 1.1) translate3d(0, 0, 0);
    }
  }

  .portfolio-content {
    transition: var(--default-transition);
  }

  &:hover {
    transform: translateY(-5px);

    .portfolio-switch-image {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }

    .portfolio-content {
      transform: translateY(-10px);
    }
  }
}

// Portfolio scattered
.portfolio-scattered {
  &.row-cols-xl-4 {
    .col {
      &:nth-child(4n + 0),
      &:nth-child(4n + 1) {
        .portfolio-box {
          @screen lg {
            padding: 15% 0;
          }
        }
      }

      &:nth-child(4n + 2),
      &:nth-child(4n + 3) {
        .portfolio-box {
          @screen lg {
            padding: 0 15%;
          }
        }
      }

      &:nth-child(8n + 0),
      &:nth-child(8n + 1),
      &:nth-child(8n + 3),
      &:nth-child(8n + 6) {
        .portfolio-box {
          padding: 25% 0;

          @screen lg {
            padding: 15% 0;
          }
        }
      }

      &:nth-child(8n + 2),
      &:nth-child(8n + 4),
      &:nth-child(8n + 5),
      &:nth-child(8n + 7) {
        .portfolio-box {
          padding: 0 15%;

          @screen lg {
            padding: 0 15% 15%;
          }
        }
      }

      &:nth-child(odd) {
        .portfolio-box {
          @screen sm {
            padding: 0 0 10%;
          }
        }
      }

      &:nth-child(even) {
        .portfolio-box {
          @screen sm {
            padding: 0 10% 10%;
          }
        }
      }
    }
  }

  &.row-cols-lg-3 {
    .col {
      &:nth-child(6n + 0),
      &:nth-child(6n + 1),
      &:nth-child(6n + 3),
      &:nth-child(6n + 5) {
        .portfolio-box {
          padding: 15% 0;
        }
      }

      &:nth-child(6n + 2),
      &:nth-child(6n + 4),
      &:nth-child(6n + 6) {
        .portfolio-box {
          padding: 0 15%;
        }
      }
    }
  }

  &.row-cols-lg-2 {
    &:nth-child(4n + 0),
    &:nth-child(4n + 1) {
      .portfolio-box {
        padding: 15% 0;
      }
    }

    &:nth-child(4n + 2),
    &:nth-child(4n + 3) {
      .portfolio-box {
        padding: 0 15%;
      }
    }
  }

  > .col {
    &:nth-child(8n + 1) {
      .portfolio-box {
        padding: 25% 0;
      }
    }

    &:nth-child(8n + 2) {
      .portfolio-box {
        padding: 0 15%;
      }
    }
  }

  .portfolio-box {
    .portfolio-image {
      clip-path: inset(0 0 0 0);
      transition: var(--default-transition);
    }

    &:hover {
      .portfolio-image {
        clip-path: inset(5% 5% 5% 5%);
      }
    }
  }

  .portfolio-hover {
    transition: var(--default-transition);
  }
}

// Portfolioslider
.portfolio-swiper-slider {
  padding-bottom: 60px;

  .swiper-button-prev,
  .swiper-button-next {
    --swiper-navigation-background: transparent;
    transition: var(--default-transition);
    @apply text-darkgray text-xmd leading-[40px] h-[40px] w-[40px] mr-[5px] rounded-full border top-auto bottom-[10px] -translate-x-1/2;

    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      border-color: #fff;
      background: #fff;
    }
  }

  .swiper-button-prev {
    left: calc(50% - 30px);
  }

  .swiper-button-next {
    left: calc(50% + 30px);
  }
}

.portfolio-slider {
  transition: var(--default-transition);
  cursor: pointer;

  .portfolio-image {
    transition: var(--default-transition);
  }

  .portfolio-hover {
    transition: var(--default-transition);
    transform: translateY(15px);
    opacity: 0;
  }

  &:hover {
    transform: translateY(-5px);

    .portfolio-image {
      background-image: linear-gradient(to right top, #0039e3, #4132e0, #5e28dd, #741bd9, #8600d4);
      transform: scale(1, 1);

      img {
        opacity: 0.15;
      }
    }

    .portfolio-hover {
      opacity: 1;
      transition-delay: 0.2s;
      transform: translateY(0);
    }
  }
}

// Portfolio Justified Gallery
.portfolio-justified {
  overflow: hidden;
  position: static;

  .portfolio-caption {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .portfolio-caption {
      opacity: 1;
      visibility: visible;
    }
  }
}
